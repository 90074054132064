import type { ParsedContent } from '@/types';
import { NStep, NSteps } from 'naive-ui';
import type { VNode } from 'vue';

export default defineNuxtComponent({
  name: 'BreadCrumbs',
  async setup() {
    const { currentCourse, currentModule, currentTask } = useContentStore();
    const { locale } = useI18n();
    const localePath = useLocalePath();
    const route = useRoute();

    const contentEntries: ParsedContent[] | null = currentModule.value?.slug
      ? ((await queryContent()
          .where({
            module: currentModule.value.slug,
            _locale: locale.value,
          })
          .find()) as ParsedContent[])
      : null;

    const hideSteps = computed<boolean>(() => !isTask(String(route.params.id)));

    async function updateCurrent(index: number): Promise<void> {
      await navigateTo(
        localePath({
          name: 'task-id',
          params: { id: `${currentModule.value?.slug}.${index}` },
        })
      );
    }

    function stepStatus(
      entry: ParsedContent,
      index: number
    ): 'wait' | 'error' | 'finish' | 'process' | undefined {
      return route.params.id === entry.slug
        ? 5 === index
          ? 'finish'
          : 'process'
        : parseInt(route.path.split('.').pop() ?? '0') > index
          ? 'finish'
          : 'wait';
    }
    return {
      contentEntries,
      currentCourse,
      currentModule,
      currentTask,
      hideSteps,
      stepStatus,
      updateCurrent,
    };
  },
  render(): VNode | null {
    if (!this.contentEntries?.length || this.hideSteps) return null;
    return (
      <NSteps
        class="mx-auto mt-6 max-w-prose"
        onUpdate:current={this.updateCurrent}
      >
        {this.contentEntries.map((entry, index) => (
          <NStep key={index} status={this.stepStatus(entry, index)}>
            <span
              class="max-w-[20%] text-sm <md:hidden"
              innerHTML={entry.shortTitle}
            />
          </NStep>
        ))}
      </NSteps>
    );
  },
});
