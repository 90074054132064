import type { VNode } from 'vue';

export default defineNuxtComponent({
  name: 'HeaderImage',
  props: {
    alt: {
      default: '',
      type: String,
    },
    src: {
      default: '',
      type: String,
    },
  },
  render(): VNode | null {
    if (!this.src) return null;
    return (
      <figure
        aria-hidden="true"
        class="pointer-events-none relative z-2 mx-auto max-w[50vw] text-center -top-16 -mb-16 <xs:hidden sm:-top-20"
      >
        <img
          alt={this.alt || ''}
          class="mx-auto max-w-full w-full"
          src={this.src}
        />
      </figure>
    );
  },
});
