import { default as index7MxYCzAHzpMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/index.tsx?macro=true";
import { default as _91id_931AjKuZFYecMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/[id].tsx?macro=true";
import { default as indexgniCZwYxPmMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/access-denied/index.tsx?macro=true";
import { default as indexhjU0EoURoXMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/admin/index.tsx?macro=true";
import { default as indexAGBWicgl1JMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/auth/index.tsx?macro=true";
import { default as _91id_93OQtVBEcbW3Meta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/course/[id].tsx?macro=true";
import { default as indexewRZ2tIv2nMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/dashboard/index.tsx?macro=true";
import { default as errorQcVZRAKRJVMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/error.tsx?macro=true";
import { default as _91id_936PhT8Dv5zyMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/module/[id].tsx?macro=true";
import { default as index1S8WWlqhQCMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/new-password/index.tsx?macro=true";
import { default as indexQMcm7gcvNCMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/parent/index.tsx?macro=true";
import { default as indexE3GA5k9ieWMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/privacy-policy/index.tsx?macro=true";
import { default as indexYxTNuEqQKcMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/profile/index.tsx?macro=true";
import { default as index2OVSmCRAZKMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/register/index.tsx?macro=true";
import { default as index2KY31xa95uMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/reset-password/index.tsx?macro=true";
import { default as indexCUyMSOvJEFMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/sign-in/index.tsx?macro=true";
import { default as indexmmNtybMVArMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/sign-out/index.tsx?macro=true";
import { default as _91id_93eSyWmdTaL5Meta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/task/[id].tsx?macro=true";
import { default as index6K5uwCKrhzMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/terms-and-conditions/index.tsx?macro=true";
import { default as indexukTufks53jMeta } from "/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/trainer/index.tsx?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/index.tsx").then(m => m.default || m)
  },
  {
    name: "id___en",
    path: "/en/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/[id].tsx").then(m => m.default || m)
  },
  {
    name: "id___fi",
    path: "/fi/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/[id].tsx").then(m => m.default || m)
  },
  {
    name: "id___vi",
    path: "/vi/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/[id].tsx").then(m => m.default || m)
  },
  {
    name: "access-denied___en",
    path: "/en/access-denied",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/access-denied/index.tsx").then(m => m.default || m)
  },
  {
    name: "access-denied___fi",
    path: "/fi/access-denied",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/access-denied/index.tsx").then(m => m.default || m)
  },
  {
    name: "access-denied___vi",
    path: "/vi/access-denied",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/access-denied/index.tsx").then(m => m.default || m)
  },
  {
    name: "admin___en",
    path: "/en/admin",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/admin/index.tsx").then(m => m.default || m)
  },
  {
    name: "admin___fi",
    path: "/fi/admin",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/admin/index.tsx").then(m => m.default || m)
  },
  {
    name: "admin___vi",
    path: "/vi/admin",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/admin/index.tsx").then(m => m.default || m)
  },
  {
    name: "auth___en",
    path: "/en/auth",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/auth/index.tsx").then(m => m.default || m)
  },
  {
    name: "auth___fi",
    path: "/fi/auth",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/auth/index.tsx").then(m => m.default || m)
  },
  {
    name: "auth___vi",
    path: "/vi/auth",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/auth/index.tsx").then(m => m.default || m)
  },
  {
    name: "course-id___en",
    path: "/en/course/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/course/[id].tsx").then(m => m.default || m)
  },
  {
    name: "course-id___fi",
    path: "/fi/kurssi/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/course/[id].tsx").then(m => m.default || m)
  },
  {
    name: "course-id___vi",
    path: "/vi/course/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/course/[id].tsx").then(m => m.default || m)
  },
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/dashboard/index.tsx").then(m => m.default || m)
  },
  {
    name: "dashboard___fi",
    path: "/fi/dashboard",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/dashboard/index.tsx").then(m => m.default || m)
  },
  {
    name: "dashboard___vi",
    path: "/vi/dashboard",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/dashboard/index.tsx").then(m => m.default || m)
  },
  {
    name: "error___en",
    path: "/en/error",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/error.tsx").then(m => m.default || m)
  },
  {
    name: "error___fi",
    path: "/fi/error",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/error.tsx").then(m => m.default || m)
  },
  {
    name: "error___vi",
    path: "/vi/error",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/error.tsx").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/index.tsx").then(m => m.default || m)
  },
  {
    name: "index___fi",
    path: "/fi",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/index.tsx").then(m => m.default || m)
  },
  {
    name: "index___vi",
    path: "/vi",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/index.tsx").then(m => m.default || m)
  },
  {
    name: "module-id___en",
    path: "/en/module/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/module/[id].tsx").then(m => m.default || m)
  },
  {
    name: "module-id___fi",
    path: "/fi/moduuli/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/module/[id].tsx").then(m => m.default || m)
  },
  {
    name: "module-id___vi",
    path: "/vi/module/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/module/[id].tsx").then(m => m.default || m)
  },
  {
    name: "new-password___en",
    path: "/en/new-password",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/new-password/index.tsx").then(m => m.default || m)
  },
  {
    name: "new-password___fi",
    path: "/fi/new-password",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/new-password/index.tsx").then(m => m.default || m)
  },
  {
    name: "new-password___vi",
    path: "/vi/new-password",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/new-password/index.tsx").then(m => m.default || m)
  },
  {
    name: "parent___en",
    path: "/en/parent",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/parent/index.tsx").then(m => m.default || m)
  },
  {
    name: "parent___fi",
    path: "/fi/huoltaja",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/parent/index.tsx").then(m => m.default || m)
  },
  {
    name: "parent___vi",
    path: "/vi/parent",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/parent/index.tsx").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/privacy-policy/index.tsx").then(m => m.default || m)
  },
  {
    name: "privacy-policy___fi",
    path: "/fi/tietosuoja",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/privacy-policy/index.tsx").then(m => m.default || m)
  },
  {
    name: "privacy-policy___vi",
    path: "/vi/privacy-policy",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/privacy-policy/index.tsx").then(m => m.default || m)
  },
  {
    name: "profile___en",
    path: "/en/profile",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/profile/index.tsx").then(m => m.default || m)
  },
  {
    name: "profile___fi",
    path: "/fi/profile",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/profile/index.tsx").then(m => m.default || m)
  },
  {
    name: "profile___vi",
    path: "/vi/profile",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/profile/index.tsx").then(m => m.default || m)
  },
  {
    name: "register___en",
    path: "/en/register",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/register/index.tsx").then(m => m.default || m)
  },
  {
    name: "register___fi",
    path: "/fi/luo-tili",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/register/index.tsx").then(m => m.default || m)
  },
  {
    name: "register___vi",
    path: "/vi/register",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/register/index.tsx").then(m => m.default || m)
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/reset-password/index.tsx").then(m => m.default || m)
  },
  {
    name: "reset-password___fi",
    path: "/fi/reset-password",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/reset-password/index.tsx").then(m => m.default || m)
  },
  {
    name: "reset-password___vi",
    path: "/vi/reset-password",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/reset-password/index.tsx").then(m => m.default || m)
  },
  {
    name: "sign-in___en",
    path: "/en/sign-in",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/sign-in/index.tsx").then(m => m.default || m)
  },
  {
    name: "sign-in___fi",
    path: "/fi/kirjaudu",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/sign-in/index.tsx").then(m => m.default || m)
  },
  {
    name: "sign-in___vi",
    path: "/vi/sign-in",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/sign-in/index.tsx").then(m => m.default || m)
  },
  {
    name: "sign-out___en",
    path: "/en/sign-out",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/sign-out/index.tsx").then(m => m.default || m)
  },
  {
    name: "sign-out___fi",
    path: "/fi/sign-out",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/sign-out/index.tsx").then(m => m.default || m)
  },
  {
    name: "sign-out___vi",
    path: "/vi/sign-out",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/sign-out/index.tsx").then(m => m.default || m)
  },
  {
    name: "task-id___en",
    path: "/en/task/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/task/[id].tsx").then(m => m.default || m)
  },
  {
    name: "task-id___fi",
    path: "/fi/tehtava/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/task/[id].tsx").then(m => m.default || m)
  },
  {
    name: "task-id___vi",
    path: "/vi/task/:id()",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/task/[id].tsx").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/terms-and-conditions/index.tsx").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___fi",
    path: "/fi/k%C3%A4ytt%C3%B6ehdot",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/terms-and-conditions/index.tsx").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___vi",
    path: "/vi/terms-and-conditions",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/terms-and-conditions/index.tsx").then(m => m.default || m)
  },
  {
    name: "trainer___en",
    path: "/en/trainer",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/trainer/index.tsx").then(m => m.default || m)
  },
  {
    name: "trainer___fi",
    path: "/fi/valmentaja",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/trainer/index.tsx").then(m => m.default || m)
  },
  {
    name: "trainer___vi",
    path: "/vi/trainer",
    component: () => import("/Users/niko/Developer/FunAcademy/fun-academy-campus/src/pages/trainer/index.tsx").then(m => m.default || m)
  }
]