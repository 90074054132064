import { I18nT } from 'vue-i18n';
import { NButton, NIcon, NPopover } from 'naive-ui';
import type { VNode } from 'vue';
import InfoIcon from '@/assets/icons/info.svg?component';
import { NuxtLink } from '#components';

export default defineNuxtComponent({
  props: {
    displayName: {
      default: '',
      type: String,
    },
    imageSrc: {
      default: '',
      type: String,
    },
  },
  name: 'ProfileImage',
  setup() {
    function handleGravatarInfo(event: Event) {
      event?.preventDefault();
      (
        document
          .getElementsByClassName('gravatar-link')
          .item(0) as HTMLAnchorElement
      )?.click();
    }
    return { handleGravatarInfo };
  },
  render(): VNode | null {
    return (
      <figure class="relative">
        <img
          alt={this.displayName}
          class="mx-auto max-w-36 w-full rounded-full ring ring-2 ring-offset-2 ring-gray-2"
          src={this.imageSrc}
        />
        <NPopover
          placement="bottom"
          v-slots={{
            trigger: () => (
              <NButton
                circle
                class="absolute -bottom-3 -right-3"
                ghost
                onClick={this.handleGravatarInfo}
                strong
              >
                {{
                  icon: () => (
                    <NIcon>
                      <InfoIcon class="w-4" />
                    </NIcon>
                  ),
                }}
              </NButton>
            ),
          }}
        >
          <I18nT keypath="profilePictureInfo" tag="span">
            <NuxtLink
              class="gravatar-link"
              external
              href="http://en.gravatar.com/"
              target="_blank"
            >
              Gravatar.com
            </NuxtLink>
          </I18nT>
        </NPopover>
      </figure>
    );
  },
});
