import type { VNode } from 'vue';
import {
  BreadCrumbs,
  ContentBackButton,
  ContentImage,
  ContentRenderer,
  ContentRendererMarkdown,
  ContentTextAttachment,
  ContentVideo,
  FooterImage,
  HeaderImage,
  SideImage,
  Steps,
} from '#components';

/**
 * Renders the content of a markdown page.
 * @package fun-academy-campus
 */
export default defineNuxtComponent({
  name: 'MarkdownPage',
  setup() {
    const { content } = useContentStore();
    const route = useRoute();
    const isLoading = ref(true);

    function showPreviewElements(): void {
      const previewElements = Array.from(
        document.getElementsByClassName('preview')
      );
      for (const el of previewElements) {
        el.removeAttribute('hidden');
      }
    }

    onMounted((): void => {
      if (route.query?.preview) showPreviewElements();
    });

    setTimeout((): void => {
      isLoading.value = false;
    }, 1000);

    return { content, isLoading };
  },
  render(): VNode {
    return (
      <article
        class="max-w-prose w-full transition-opacity duration-300"
        itemscope
        itemtype="https://schema.org/Article"
      >
        <meta content="Fun Academy" itemprop="author" />
        <HeaderImage
          alt={this.content?.headerImage?.alt ?? ''}
          src={this.content?.headerImage?.src ?? undefined}
        />
        <BreadCrumbs />
        <Steps />
        <ContentTextAttachment />
        <ContentBackButton
          label={this.content?.backButton?.label}
          preview={this.content?.backButton?.query?.preview}
          to={this.content?.backButton?.to}
        />
        <ContentImage fullSize={this.content?.contentImage?.fullSize} />
        {'manual' !== this.content?.videoPosition && <ContentVideo />}
        <SideImage />
        {this.content && (
          <div class="content">
            <ContentRenderer key={this.content._id} value={this.content.body}>
              <ContentRendererMarkdown value={this.content} />
            </ContentRenderer>
          </div>
        )}
        <FooterImage
          alt={this.content?.footerImage?.alt}
          src={this.content?.footerImage?.src}
        />
      </article>
    );
  },
});
