import type { VNode } from 'vue';

export default defineNuxtComponent({
  name: 'FooterImage',
  props: {
    alt: {
      default: '',
      type: String,
    },
    src: {
      default: '',
      type: String,
    },
  },
  render(): VNode | null {
    return this.src ? (
      <div class="text-center">
        <img alt={this.alt || ''} class="mt-6 max-w-full w-60" src={this.src} />
      </div>
    ) : null;
  },
});
