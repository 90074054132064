import { NuxtLink, NuxtLinkLocale } from '#components';
import { NBreadcrumb, NBreadcrumbItem } from 'naive-ui';
import type { VNode } from 'vue';

export default defineNuxtComponent({
  name: 'BreadCrumbs',
  setup() {
    const { currentTask, currentCourse, currentModule } = useContentStore();
    const route = useRoute();
    const hideBreadCrumbs = computed<boolean>(
      () =>
        !isModule(String(route.params.id)) && !isTask(String(route.params.id))
    );
    return {
      currentTask,
      currentCourse,
      currentModule,
      hideBreadCrumbs,
    };
  },
  render(): VNode | null {
    if (this.hideBreadCrumbs) return null;
    return (
      <NBreadcrumb class="mx-auto max-w-prose w-full whitespace-normal">
        <NBreadcrumbItem>
          <NuxtLinkLocale to="index">{this.$t('home')}</NuxtLinkLocale>
        </NBreadcrumbItem>
        {this.currentCourse ? (
          <NBreadcrumbItem>
            <NuxtLink to={this.currentCourse?._path}>
              {this.currentCourse?.title}
            </NuxtLink>
          </NBreadcrumbItem>
        ) : null}
        {this.currentModule && (
          <NBreadcrumbItem>
            <NuxtLink to={this.currentModule?._path}>
              {this.currentModule?.title}
            </NuxtLink>
          </NBreadcrumbItem>
        )}
        {this.currentTask ? (
          <NBreadcrumbItem>
            <NuxtLink to={this.currentTask?._path}>
              {this.currentTask?.title}
            </NuxtLink>
          </NBreadcrumbItem>
        ) : null}
      </NBreadcrumb>
    );
  },
});
