import Characters from '@/assets/characters/3.svg?component';
import Clouds from '@/assets/images/clouds.svg?url';
import { NButton } from 'naive-ui';
import type { PropType, VNode } from 'vue';
import ContentCard from './components/content/ContentCard';

export default defineNuxtComponent({
  name: 'GlobalError',
  props: {
    error: {
      default: null,
      type: Object as PropType<{ message: string } | null>,
    },
  },
  setup() {
    const localePath = useLocalePath();
    const handleError = () =>
      window.location.assign(localePath({ name: 'index' }));
    useHead({
      htmlAttrs: {
        class: 'min-h-screen grid place-items-center bg-cover',
        style: `background: url(${Clouds}) no-repeat bottom center;`,
      },
    });
    return { handleError };
  },
  render(): VNode | null {
    if (!this.error) return null;
    return (
      <ContentCard class="max-w-content mx-auto p-4 text-center shadow-sm container">
        <Characters class="mx-auto h-40 w-40" />
        <h1 class="mt-0">{this.error?.message}</h1>
        <p class="text-lg">There was an error 😱</p>
        <p>
          <NButton class="mt-3" onClick={this.handleError} round type="default">
            {this.$t('reloadApp')}
          </NButton>
        </p>
      </ContentCard>
    );
  },
});
